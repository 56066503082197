export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "Sources",
    path: "/sources",
  },
  {
    text: "Products",
    path: "/products",
  },
  {
    text: "contact",
    path: "/contact",
  },
]
export const Sources = [
  {
    cover: "../images/hero/sail.png",
    name: "Alloy Steel Plant (SAIL/ASP)",
    
  },
  {
    cover: "../images/hero/bsp.jpg",
    name: "Bhushan Power & Steel Limited",
    
  },
  {
    cover: "../images/hero/jsw.jpg",
    name: "JSW Limited",
   
  },
  {
    cover: "../images/hero/Kalyani.png",
    name: "Kalyani Steel",
   
  },
  {
    cover: "../images/hero/lt.png",
    name: "L&T Special Steels",
    
  },
  {
    cover: "../images/hero/rashtriya.png",
    name: "Rashtriya Ispat Nigam Limited",
    
  },
  {
    cover: "../images/hero/ajra.png",
    name: "Arjas Steel",
    
  },
  {
    cover: "../images/hero/sail.png",
    name: "Visvesvaraya Iron & Steel",
    
  },
]
export const list = [
  {
    id: 1,
    cover: "../images/list/BrightBars.jpg",
    name: "Bright Bars",
    subTitle: "Size: 6mm to 2000mm",
  },
  {
    id: 2,
    cover: "../images/list/wire.jpg",
    name: "Wire Rods",
    subTitle: "Size: 6mm to 2000mm",
    
  },
  {
    id: 3,
    cover: "../images/list/StainlessSteel.jpg",
    name: "Stainless Sheets & Plates",
    subTitle: "Size: Thickness 5mm to 2000mm Width 20mm to 2000mm",
    
  },
  {
    id: 4,
    cover: "../images/list/SteelBillets.jpg",
    name: "Steel Billets",
    subTitle: "Size: 25mm to 2000mm",
    
  },
  // {
  //   id: 5,
  //   cover: "../images/list/ForgedShafts.jpg",
  //   name: "Forged Shafts",
  //   subTitle: "Size: 6mm to 2000mm",
    
  // },
  {
    id: 5,
    cover: "../images/list/alloy.jpg",
    name: "Alloy Steel Bar",
    subTitle: "Size: 6mm to 2000mm",
    
  },
  {
    id: 6,
    cover: "../images/list/shaft.jpg",
    name: " Machined Shafts",
    subTitle: "Size: 6mm to 2000mm",
    
  },
  {
    id: 7,
    cover: "../images/list/block.jpg",
    name: "Die Blocks",
    subTitle: "Size: 6mm to 2000mm",
    
  },
  {
    id: 8,
    cover: "../images/list/blooms.jpg",
    name: "Steel Blooms",
    subTitle: "Size: 6mm to 2000mm",
    
  },
]
export const ProductsSizes = [
  {
    id: 1,
    name: "• Carbon Steel:",
    size: " A105, C-15, C-20, C-35, C-45, C-55, 11SMNPb30, etc.",
  },
  {
    id: 2,
    name: "• Free Cutting Steel:",
    size: " EN1A, EN7, SAE1117, etc.",
  },
  {
    id: 3,
    name: "• Carbon Mn Steel:",
    size: " 20Mn2, S355J2G3, SAE1140, SAE1141, SAE1514, etc.",
  },
  {
    id: 4,
    name: "• Chromium Mn Steel:",
    size: " 41Cr4, etc.",
  },
  {
    id: 5,
    name: "• Direct Hardening Steel:",
    size: " En19, En24, 34CrM04, 42CrM04,34CrNiM06, 30CrNimo8, etc.",
  },
  {
    id: 6,
    name: "• Case Hardening Steel:",
    size: " EN36C, 16MnCr5, 20MnCr5, SAE8620, SAE8822, En353, En354, 17CrNiMo6, 18CrNiMo6-7, etc.",
  },
  {
    id: 7,
    name: "• Die Block:",
    size: " DIN2714, 56NIC MOV7, AISI H-11, AISI H-13, AISI P-20 (Pre Hardened), etc.",
  },
  {
    id: 8,
    name: "• Cold Work Tool Steel:",
    size: " AISI D-2, AISI D-3, 160Cr12, 165CrV12, 210Cr12, 160CrMoV12, etc.",
  },
  {
    id: 9,
    name: "• Stainless Steel:",
    size: " AISI 309, AISI 310, AISI 321, AISI 410, AISI 420, AISI 304L, AISI 316L, etc.",
  },
  {
    id: 10,
    name: "• Shock Resisting Steels:",
    size: " 55WCrV7, 60WCrV7, 45WCrV7, 45SiCrV6 etc.",
  },
  {
    id: 11,
    name: "• Ball Bearing Steels:",
    size: " SAE 52100, EN31, etc.",
  },
  {
    id: 12,
    name: "• Creep Resisting Steels:",
    size: " F5, F9, F11, F12, F22, etc.",
  },
  {
    id: 13,
    name: "• High Speed Steels:",
    size: " M2, M35, ASP23, ASP 30, ASP 60 etc.",
  },
  {
    id: 14,
    name: "• Nitriding Steels:",
    size: " EN29B, EN40B, EN418, 40Cr7AL10M02, 33ALCrMo4, etc.",
  }
]
export const Achievement = [
  {
    icon: <i class="fa-solid fa-user"></i>,
    num: "32 M	s",
    name: "Total Customers",
  },
  {
    icon: <i class="fa-brands fa-product-hunt"></i>,
    num: "9",
    name: "Products",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "51 M",
    name: "Indian ",
  },
  {
    icon: <i class='fa-solid fa-heart'></i>,
    num: "42 M",
    name: "Steel",
  },
]

export const footer = [
  
  {
    title: "COMPANY",
    text: [{ list: "About" }, { list: "Contact" }, { list: "Sources" }, { list: "Products" }],
  },
]
