import React from "react"
import img from "../../components/images/round.jpg"
import Back from "../../components/common/Back"
import Heading from "../../components/common/Heading"
import "./contact.css"

const Contact = () => {
  return (
    <>
      <section className='contact mb'>
        <Back name='Contact Us' title='Get Helps & Friendly Support' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Contact Us ?..' subtitle='Check out our Company Detailes below to contact us' />
            <p><strong style={{color: "black"}}>Company Name:</strong> Southern Steels And Forgings</p>
            <p><strong style={{color: "black"}}>Branches :</strong> Hyderabad, Bangalore, Chennai</p>
            <p><strong style={{color: "black"}}>Email Address :</strong> hydssf@gmail.com (Hyderabad), ssfchennai@gmail.com (Chennai), Pushpa.ssf@kemsltd.com (Bangalore)</p>
            <p><strong style={{color: "black"}}>Hyderabad Contact No :</strong> +91 93475 78899 (Hitesh dasot), +91 93469 89678 (Gaurav dasot)</p>
            <p><strong style={{color: "black"}}>Chennai Contact No :</strong> +91 98400 55140 (Vivek dasot) ,+91 93821 16216 (Gautam dasot)</p>
            <p><strong style={{color: "black"}}>Bangalore Contact No :</strong> 080-2308 2606, 080-2308 2605 , +91 98440 17085 (Deepak Gandhi)</p>
            <p><strong style={{color: "black"}}>Office No :</strong> +91 40 4954 6698 </p>
            {/*<p><strong style={{color: "black"}}>Mobile No :</strong> +91 93475 78899 , +91 93469 89678 , +91 90000 12517</p>*/}
            <p><strong style={{color: "black"}}>Address :</strong> 5A/9, TECHNO CRAT INDUSTRIAL ESTATE 1st floor Near Andhra bank BALANAGAR Hyderabad-37.</p>
            <p><strong style={{color: "black"}}>Pin Code :</strong> 500037</p>
          </div>
          <div className='right row'>
            {/*<img src='./contact.jpg'style={{width:"350px"}}  alt='' />*/}
          </div>
        </div>
        <div className="location">
          <Heading title='Company Location' subtitle='Click on the directions to open the maps'/>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7611.62515225985!2d78.43835044474578!3d17.468682488500413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9057bfffffff%3A0xe0c9a19d0d7b034!2sTechnocrat%20Industrial%20Estate%2C!5e0!3m2!1sen!2sin!4v1706461387598!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </section>
    </>
  )
}

export default Contact
