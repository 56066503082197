import React from "react"
import { Sources } from "../data/Data"

const SourcesCard = () => {
  return (
    <>
      <div className='content grid4 mtop'>
        {Sources.map((items, index) => (
          <div className='box' key={index}>
            <img src={items.cover} alt='' />
            <h4>{items.name}</h4>
            
          </div>
        ))}
      </div>
    </>
  )
}

export default SourcesCard
