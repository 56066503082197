import React from "react"
import { footer } from "../../data/Data"
import "./footer.css"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <>
      <section className='footerContact'>
        <div className='container'>
          <div className='send flex'>
            <div className='text'>
              <h1>Do You Have Questions ?</h1>
              {/*<p>We are available 24/7 at any time.</p>*/}
            </div>
            <Link to='/contact'>
            <button className='btn2'>Contact Us</button>
            </Link>
          </div>
        </div>
  </section>

      {/*<footer>
        <div className='container'>
          <div className='box'>
            <div className='logo'>
              
              <h1 style={{color:'#2766ae'}}>SSF</h1>
              <h2>Do You Need Help With Anything?</h2>
              <p>Receive updates, and News about our company in this website</p>

              <div className='input flex'>
                <input type='text' placeholder='Email Address' />
                <button>Subscribe</button>
              </div>
            </div>
          </div>

            
          {footer.map((val) => (
            <div className='box'>
              <h3>{val.title}</h3>
              <ul>
                {val.text.map((items) => (
                  <li> {items.list} </li>
                ))}
              </ul>
            </div>
          ))}

            <div className='box'>
              <div className='text'>
                <h2>Do You Have Questions ?</h2>
                <p>We are available 24/7 at any time.</p>
              </div>
              <Link to="/contact"><button className='btn2'>Contact Us</button></Link>
              
            </div>
          
        </div>
                </footer>*/}
      <div className='legal'>
        <span>© 2024. Designd By K Shiva Prasad Reddy </span>
        <span>(kshiva8109@gmail.com)</span>
      </div>
    </>
  )
}

export default Footer
