import React from "react"
import { list } from "../data/Data"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

const ProductCard = () => {
  return (
    <>
      <div className='content grid3 mtop'>
        {list.map((val, index) => {
          const { cover, subTitle, name } = val
          return (
            <div className='box shadow' key={index}>
              <div className='img'>
                <img src={cover} style={{height:"250px"}} alt='' />
              </div>
              
              <div className="text">
                <div className="category flex">
                  <div style={{padding:"11px"}}>
                    <h4>{name}</h4>
                    {/*<p>{subTitle}</p>*/}
                  </div>
                  <div>
                  <Link to="/contact"><button className='btn2' style={{padding:"10px 20px"}}>Enquire</button></Link>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ProductCard
