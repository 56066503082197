import React from "react"
import Back from "../../components/common/Back"
import Heading from "../../components/common/Heading"
import img from "../../components/images/round.jpg"
import "./about.css"

const About = () => {
  return (
    <>
      <section className='about'>
        <Back name='About Us' title='About Us - Why us?' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='About us' subtitle='' />

            <p>We are one of the prominent channel partners of leading Steel mills in India handling Carbon, Alloy, Stainless and Tool Steels apart from Special Steels meeting our customer's requirement. We handle raw material from leading Steel Manufacturers in India and supply it to various Forging and Engineering Industries. Our customers are located throughout India.</p>
            <p>At present, we sell around 5000 Tons of Steel per month and our stock at any given point of time, will be around 2500 Tons per month.</p>
            {/*<button className='btn2'>More About Us</button>*/}
          </div>
          <div className='right row'>
            {/*<img src='./aboutus.jpg' alt='' />*/}
          </div>
        </div>
        <div className='container flex mtop'>
          <div className='left row'>
            {/*<img src='./whyus.jpg'style={{width:"350px", padding:"18px"}}  alt='' />*/}
          </div>
          <div className='right row'>
            <Heading title='Why Us ?..' subtitle='' />
            <p><strong style={{color: "black"}}>Supply Chain Management :</strong> • Sourcing • Cost effective, High Standard Solution Offering • Values Added Services • Transportation • Warehousing • Delivery</p>
            <p><strong style={{color: "black"}}>Trade Offer :</strong> • Customer oriented procedure for undertaking business • Response to enquiries within 12 hours • Development process starts immediately on approval of quotation</p>
            <p><strong style={{color: "black"}}>Infrastructure :</strong> • We have warehousing and handling facilities in Bangalore, Chennai, Hyderabad, Kolkata, Mumbai and Pune with delivery across India with total capacity of 10,000 tons of material • Liaisoning offices in Delhi, Indore & Coimbatore</p>
          </div>
        </div>

        <div className='container flex mtop'>
          <div className='left row'>
            {/*<img src='./whyus.jpg'style={{width:"350px", padding:"18px"}}  alt='' />*/}
          </div>
          <div className='right row'>
            <Heading title='Our Policy,Vision & Mission' subtitle='' />
            <p><strong style={{color: "black"}}>Company Policy :</strong> Our company policy is to provide customer satisfaction by giving them High Quality Steels in various international standards such as, Russian, German, European, British, American & Japan standards at reasonable prices. Our commitment to quality is uncompromising.</p>
            <p><strong style={{color: "black"}}>Our Vision :</strong> To earn due respect in the steel industry by serving with complete integrity, commitment & loyalty towards clientele.</p>
            <p><strong style={{color: "black"}}>Our Mission :</strong> To be a world class steel group through Total Customer Satisfaction & enhancing Profitability through continues improvement, innovation & new technology operational excellence supported by continues human resource development.</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
