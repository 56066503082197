import "./App.css"
import Header from "./components/common/header/Header"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "./pages/home/Home"
import Footer from "./components/common/footer/Footer"
import About from "./pages/about/About"
import products from "./pages/products/products"
import Services from "./pages/services/Services"
import Contact from "./pages/contact/Contact"
import ScrollToTop from "./components/common/ScrollToTop"

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          <Route exact path='/sources' component={Services} />
          <Route exact path='/products' component={products} />
          <Route exact path='/contact' component={Contact} />
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default App
