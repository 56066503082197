import React from "react"
import Back from "../../components/common/Back"
import ProductCard from "../../components/product/ProductCard"
import "../../components/product/product.css"
import img from "../../components/images/round.jpg"
import "./products.css"
import Heading from "../../components/common/Heading"
import { ProductsSizes } from "../../components/data/Data"

const ProductPage = () => {
  return (
    <>
      <section className='products mb'>
        <Back name='Products' title='Our Products' cover={img} />
        <div className='container recent'>
          <ProductCard />
        </div>
        <div className='container'>
            <Heading title='Varieties' subtitle='Check out our different product Varieties' />
            {ProductsSizes.map((val, index) => {
              const { name, size } = val
              return (
                <p><strong style={{color: "black"}}>{name}</strong>{size}</p>
              )
            })}
            </div>
      </section>
    </>
  )
}

export default ProductPage
