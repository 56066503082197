import React from "react"
import Heading from "../common/Heading"
import "./hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <Heading title='Southern Steels and Forgings ' subtitle='Elevating Innovation, Forging Strength: Southern Steels & Forgings - Your Alloy Steel Partner' />
        </div>
      </section>
    </>
  )
}

export default Hero
