import React from "react"
import Heading from "../common/Heading"
import "./product.css"
import ProductCard from "./ProductCard"

const Product = () => {
  return (
    <>
      <section className='Product padding'>
        <div className='container'>
          <Heading title='Our Products' subtitle='' />
          <ProductCard />
        </div>
      </section>
    </>
  )
}

export default Product
