import React from "react"
import Achievements from "../../components/Achievements/Achievements"
import Sources from "../../components/sources/sources"
import Hero from "../../components/hero/Hero"
import Product from "../../components/product/product"
import About from "../about/About"

const Home = () => {
  return (
    <>
      <Hero />
      <Sources />
      <Product />
    </>
  )
}

export default Home
